
import axios from "axios";
import PanelCard from './PanelCard.vue';
import whitelist from "static/json/personal_article_video_whitelist.json"
import FalconAd from "../widget/FalconAd.vue";
export default {
    name: "panel-component",
    components: { 
        PanelCard,
        FalconAd,
    },
    props: {
        openType: {
            type: String
        }
    },
    data() {
        return {
            active_tab: 'all',
            notification_list_all: [],
            openOfficialModal: false,
            officialMessage: {
                main_image: ''
            }
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        // lv2 會員 member_name 為 null，識別是用 member_uniqid
        // lv4 會員才有 member_name
        has_member_name() {
            return this.member_data.member_name !== null;
        },
        link_blog() {
            if (this.has_member_name) {
                return `https://${this.member_data.member_name}.pixnet.net/blog`;
            } else {
                return `https://www.pixnet.net/pcard/${this.member_data.member_uniqid}/profile/blog`;
            }
        },
        link_collection() {
            let urlKey = this.has_member_name ? this.member_data.member_name : this.member_data.member_uniqid;
            return `https://www.pixnet.net/pcard/${urlKey}/mycollection/folders`;
        },
        link_topic() {
            let urlKey = this.has_member_name ? this.member_data.member_name : this.member_data.member_uniqid;
            return `https://www.pixnet.net/pcard/${urlKey}/profile/topic`;
        },
        notification_list_official() {
            return this.notification_list_all.filter(item => item.type === "OFFICIAL");
        },
        has_unread() {
            return this.notification_list_all.find(item => item.is_read === false) !== undefined;
        },
        in_whitelist() {
            if (this.is_login) {
                // TODO: 應該還要檢查 show_business_card，但目前API還沒有
                return whitelist.includes(this.member_data.member_uniqid);
            }
            return false;
        }
    },
    methods: {
        readMessage(message) {
            if (!message.is_read) {
                this.readNotifications(message.id);
            }
            let isReadIdx = this.notification_list_all.findIndex(item => item.id === message.id);
            this.notification_list_all[isReadIdx].is_read = true;
            if (message.type === 'NORMAL') {
                location.href = message.action_link;
            } else if (message.type === 'OFFICIAL') {
                this.showOfficialNotification(message.id);
            }
        },
        goLogin(){
            const url = window.location.host;
            location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2F"+ url +"%3Fversions%3D2023";
        },
        async getNotifications() {
            const API_url = "https://api.pixnet.cc/navbar/v1/notifications";
            try {
                const data = await axios({
                    method: 'get',
                    url: API_url,
                    withCredentials: true
                });
                if (data.status === 200 && !data.error) {
                    this.notification_list_all = data.data.data;
                    this.$emit('has-unread', this.has_unread);
                } else {
                    return Promise.reject(data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        async readNotifications(id) {
            const API_url = `https://api.pixnet.cc/navbar/v1/notification/${id}/read`;
            try {
                const data = await axios({
                    method: 'post',
                    url: API_url,
                    withCredentials: true
                });
                if (data.status === 200 && !data.error) {
                    this.getNotifications();
                } else {
                    return Promise.reject(data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        // 讀取官方消息api 有打通 資料還沒有串燈箱
        async showOfficialNotification(id) {
            document.cookie = 'XSRF-TOKEN=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            const API_url = `https://api.pixnet.cc/navbar/v1/notification/${id}`;
            try {
                const data = await axios({
                    method: 'get',
                    url: API_url,
                    withCredentials: true
                });
                if (data.status === 200 && !data.error) {
                    this.openOfficialModal = true;
                    this.officialMessage = data.data.data;
                    if (window.screen.width > 1024) {
                        this.$emit('close-panel');
                    }
                } else {
                    return Promise.reject(data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        goCreateArticle() {
            if(this.is_login) {
                location.href = "https://panel.pixnet.cc/#/create-article";
            } else {
                location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2Fpanel.pixnet.cc%2F%23%2Fcreate-article";
            }
        },
        rebootPixAd() {
            if(window.PIXFalcon){
                var pixAd;
                pixAd = new PIXFalcon();
                pixAd.placeAds(document);
            }
        },
    },
    watch: {
        is_login: function() {
            if (this.is_login) {
                this.getNotifications();
            }
        },
        has_unread(val) {
            if (!this.has_unread) {
                this.$emit('has-unread', val);
            }
        }
    },
    mounted() {
        if (this.is_login) {
            this.getNotifications();
        }
        this.rebootPixAd();
    }
}
